<template>
  <div class="w-100">
    <b-overlay :show="loading">

      <b-card no-body class="card-statistics">
        <b-card-body class="statistics-body">
          <b-row>
            <b-col md="3" v-for="(val, index) in reports" :key="index">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="val.currency.name === 'usd' ? 'primary' : 'success'">
                    <feather-icon size="24" :icon="val.currency.name === 'usd' ? 'DollarSignIcon' : 'TrendingUpIcon'" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <!--                <h4 class="font-weight-bolder mb-0">-->
                  <!--                  {{$t('message.Actual_price')}}-->
                  <!--                </h4>-->
                  <b-card-text class="font-small-3 mb-0">
                    <h4 class="w-100">
                      {{val.total | moneyDisplayFormat}} {{val.currency.name}}
                    </h4>
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-row class="align-items-end">
          <b-col lg="3" v-if="checkRoleUser">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('message.Branches')}}
              </p>
              <div class="wrapper-form">
                <v-select class="w-100" label="name" v-model="filter.branch_id" :options="branchesOptions"
                  @input="getDataByFilter"></v-select>
              </div>
            </div>
          </b-col>

          <b-col lg="3">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('message.Service')}}
              </p>
              <div class="wrapper-form">
                <v-select class="w-100" label="name" v-model="filter.service" :options="serviceOptions"
                  @input="getDataByFilter"></v-select>
              </div>
            </div>
          </b-col>

          <b-col lg="3">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('message.tableHeader.date')}}
              </p>
              <div class="">
                <input type="date" v-model="filter.date" class="form-control" @input="getDataByFilter">
              </div>
            </div>
          </b-col>

          <b-col lg="3" class="ml-auto">
            <div class="d-flex justify-content-end w-100 mt-1">
              <b-button variant="primary" @click="getDataByFilter">
                {{$t('message.seachLabel')}}
              </b-button>

              <b-button class="ml-2" variant="primary" @click="handleAddModal">
                {{$t('message.AddPayment')}}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-table :fields="fields" :items="datas" responsive :busy="busy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(status)="{item}">
            <span v-if="item.status.id === 1" class="text-success">
              {{$t('Payed')}}
            </span>

            <span v-else-if="item.status.id === 4" class="text-danger">
              {{$t('Rejected')}}
            </span>
            <span v-else class="text-warning">
              {{item.status.name_ru}}
            </span>
          </template>
          <template #cell(price)="{item}">
            {{item.price}} {{item.currency.display_name}}
          </template>
          <template #cell(created_at)="{item}">
            {{item.created_at | dateFormat}}
          </template>
          <template #cell(action)="{item}">
            <b-dropdown variant="primary" size="sm" v-if="item.status_id !== 5">
              <template #button-content>
                <b-icon icon="gear-fill" aria-hidden="true"></b-icon> {{$t('Settings')}}
              </template>
              <b-dropdown-item class="w-100" @click="generateCheque(item)">
                <div class="w-100">
                  {{$t('Cheque')}}
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="handlePenaltyModalOpen(item.id)" v-if="item.status.id !== 4" variant="danger">
                {{$t('message.Cancel')}}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>

      <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize" @input="fetchList">
      </b-pagination>
    </b-overlay>


    <b-modal v-model="addModal" hide-footer no-close-on-backdrop id="modal-lg" size="lg">
      <AddModal @handleClose="closeAddModal" />
    </b-modal>

    <b-modal v-model="chequeActive" hide-footer>
      <ChequeModal :chequeData="activeCheque" />
    </b-modal>
    <b-modal v-model="penaltyModalBool" no-close-on-backdrop hide-footer>
      <PenaltyModal :id="id" @handleClose="handlePenaltyModalClose" />
    </b-modal>
  </div>
</template>

<script>
  import ChequeModal from '@/views/payments/components/ChequeModal.vue'
  import AddModal from '@/views/payments/components/PaymentAddModal.vue'
  import moment from 'moment';
  import {
    deletePayment,
    getPayments,
    updataPaymentStatus
  } from '@/api/payments'
  import {
    listTemplate
  } from '@/utils/mixins/listTemplate'
  import {
    getBranches
  } from '@/api/branchs/branchs'
  import {
    getServices
  } from '@/api/services/services';
  import {
    mapGetters
  } from 'vuex';
  import PenaltyModal from '@/views/payments/components/PaymentPenaltyModal.vue'

  const actions = {
    get: getPayments,
    delete: deletePayment
  }

  export default {
    name: 'Payments',
    components: {
      ChequeModal,
      AddModal,
      PenaltyModal
    },
    // mixins: [
    //   listTemplate,
    // ],
    data() {
      return {
        actions,
        payments: [],
        fields: [{
            key: 'id',
            label: this.$t('message.ID')
          },
          {
            key: 'status',
            label: this.$t('Status')
          },
          {
            key: 'service.name_ru',
            label: this.$t('message.Service')
          },
          {
            key: 'client.full_name',
            label: this.$t('message.Client')
          },
          {
            key: 'price',
            label: this.$t('message.Price')
          },
          {
            key: 'branch.name',
            label: this.$t('message.Branch')
          },
          {
            key: 'created_at',
            label: this.$t('message.CreatedAt')
          },
          {
            key: "action",
            label: this.$t('message.Action')
          }
        ],
        chequeActive: false,
        activeCheque: null,
        filter: {
          branch_id: null,
          date: null,
          service: null,
        },
        filters: {
          branch_id: null,
          date: null,
          service_id: null,
        },
        pagination: {
          page: 1,
          pageSize: 10,
        },
        total: 1,
        branchesOptions: [],
        serviceOptions: [],
        addModal: false,
        penaltyModalBool: false,
        id: null,
        reports: [],
        datas: [],
        busy: true,
        loading: false,
      }
    },
    computed: {
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if (this.activeUser !== null) {
          return this.activeUser.roles.some(item => item.name == 'SuperAdmin')
        }
        return false
      }
    },
    methods: {
      fetchList() {
        this.loading = true;
        actions.get({
          ...this.filters,
          ...this.pagination
        }).then(res => {
          this.datas = res.data.data.data;
          this.reports = res.data.reports;
          this.total = res.data.data.total;
          this.busy = false;
          this.loading = false;
        })
      },
      generateCheque(data) {
        this.activeCheque = data;
        this.chequeActive = true;
      },
      getDataByFilter() {
        this.filters.branch_id = this.filter.branch_id ? this.filter.branch_id.id : null;
        this.filters.date = this.filter.date ? moment(this.filter.date).format('YYYY-MM-DD') : null;
        this.filters.service_id = this.filter.service ? this.filter.service.id : null;
        this.fetchList();
      },
      getBranchesOptions() {
        getBranches().then(res => {
          this.branchesOptions = res.data.data
        })
      },
      getServiceOptions() {
        // this.serviceOptions
        getServices().then(res => {
          this.serviceOptions = res.data.data
        })
      },
      handleAddModal() {
        this.addModal = true;
      },
      closeAddModal() {
        this.addModal = false;
        this.fetchList();
      },
      handleCancel(id) {
        let data = {
          id: id,
          status_id: 4
        }
        updataPaymentStatus(data).then(() => {
          this.fetchList();
        })
      },
      handlePenaltyModalClose() {
        this.id = null;
        this.penaltyModalBool = false;
        this.fetchList();
      },
      handlePenaltyModalOpen(id) {
        this.id = id
        this.penaltyModalBool = true;
      }
    },
    mounted() {
      this.fetchList()
      this.getBranchesOptions();
      this.getServiceOptions();
    }
  }
</script>