<template>
  <div class="container">
    <b-overlay :show="loading" rounded="sm">
      <ValidationObserver ref="observer">
        <div>
          <h4 class="text-center" v-if="disabledItem">
            {{$t('message.Payed')}}
          </h4>
          <div class="w-100 d-flex justify-content-end mb-1" v-else>
            <b-button @click="addQRData" variant="success" :disabled="disabledItem">
              {{$t('messageAddPuy')}}
            </b-button>
          </div>

          <div class="wrapper" v-if="author.length !== 0">
            <p class="wrapper-title">
              {{$t('message.Author')}}
            </p>
            <div class="wrapper-form" style="padding: 8px 0">
              <b-icon icon="person-fill"></b-icon>
              <p class="mb-0">
                {{author}}
              </p>
            </div>
          </div>

          <div class="wrapper" v-if="clientName.length !== 0">
            <p class="wrapper-title">
              {{$t('message.Full_name')}}
            </p>
            <div class="wrapper-form" style="padding: 8px 0">
              <b-icon icon="person-fill"></b-icon>
              <p class="mb-0">
                {{clientName}}
              </p>
            </div>
          </div>

          <div class="wrapper" v-for="(item, index) in clientNumbers" :key="index">
            <p class="wrapper-title">
              {{$t('data.mobile_number')}}
            </p>
            <div class="wrapper-form" style="padding: 8px 0">
              <b-icon icon="person-fill"></b-icon>
              <p class="mb-0">
                {{item.number === null ? `${$t('error.number')}` : item.number}}
              </p>
            </div>
          </div>

          <ValidationProvider #default="validationContext" :name="$t('data.Treaty')" rules="required">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.Treaty')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="file-earmark-binary"></b-icon>
                <v-select v-model="contract" class="w-100" label="document_number" :options="treatyOptions" taggable
                  :pushTags="true" @search="treatySearch" @option:selected="treatySelected" :disabled="disabledItem">
                </v-select>
              </div>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </div>
          </ValidationProvider>

          <ValidationProvider #default="validationContext" :name="$t('message.Price')" rules="required">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('message.Price')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="wallet2"></b-icon>
                <b-form-input v-model="form.price" type="number" :disabled="disabledItem"></b-form-input>
              </div>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </div>
          </ValidationProvider>

          <ValidationProvider #default="validationContext" :name="$t('data.payment_type')" rules="required">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.payment_type')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="wallet2"></b-icon>
                <v-select v-model="payment_type" label="name_ru" :options="paymentType" :disabled="disabledItem">
                </v-select>
              </div>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </div>
          </ValidationProvider>

          <ValidationProvider #default="validationContext" :name="$t('data.payment_methods')" rules="required">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.payment_methods')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="wallet2"></b-icon>
                <v-select v-model="currency" label="name" :options="currencies" :disabled="disabledItem"></v-select>
              </div>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </div>
          </ValidationProvider>

          <div class="d-flex justify-content-end mt-1">
            <b-button variant="warning" @click="handleClose">
              {{$t('message.back')}}
            </b-button>

            <b-button class="ml-2" variant="primary" :disabled="disabledItem" @click="handleSubmit">
              {{$t('message.Pay')}}
            </b-button>
          </div>
        </div>
      </ValidationObserver>
    </b-overlay>

    <b-modal v-model="codeModal" hide-footer no-close-on-backdrop>
      <div class="container">
        <div class="wrapper">
          <p class="wrapper-title">
            {{$t('data.Treaty')}}
          </p>
          <div class="wrapper-form">
            <b-icon icon="wallet2"></b-icon>
            <b-form-input v-model="get_contract_by_qr" autofocus ref="qrInput" @change="getQrContract"></b-form-input>
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate';
  import {
    required
  } from '@validations';
  import {
    getContracts,
    contractPayment
  } from '@/api/payments/contracts';
  import {
    mapActions,
    mapGetters
  } from 'vuex';
  import {
    getQRCode
  } from '@/api/qr_code/qr_code'
  import {
    fetchContractItem
  } from '@/api/payment-types/payment-types'

  export default {
    name: "PaymentAddModal",
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        required,
        treatyOptions: [],
        treatyTimeout: null,
        contract: null,
        currency: null,
        payment_type: null,
        form: {
          contract_id: null,
          price: null,
          payment_type_id: null,
          currency_id: null
        },
        codeModal: false,
        get_contract_by_qr: '',
        disabledItem: false,
        disabledButton: false,
        loading: false,
        clientName: '',
        clientNumbers: [],
        author: ''
      }
    },
    computed: {
      ...mapGetters('settings', ['paymentType', 'currencies'])
    },
    methods: {
      ...mapActions('settings', ['fetchMethods', 'fetchCurrencies']),
      treatySearch(item) {
        if (item.length > 0) {
          clearTimeout(this.treatyTimeout);

          this.treatyTimeout = setTimeout(() => {
            getContracts({
              search: item
            }).then(res => {
              this.treatyOptions = res.data.data.data
            })
          }, 600)
        }
      },
      treatySelected(item) {
        this.loading = true
        fetchContractItem({
          service_id: item.service_id,
          service_item_id: item.service_item_id
        }).then(res => {
          // this.payment_type = res.data.data.payment_type;
          this.currency = res.data.data.currency;

          console.log(item);

          item.client.contacts = JSON.parse(item.client.contacts);
          this.clientName = item.client.full_name;
          this.clientNumbers = item.client.contacts;
          this.author = item.author.name

          this.loading = false;

        })
        if (item.cur_status_id === 3) {
          this.disabledItem = true;
        } else if (item.cur_status_id === 2) {
          this.form.price = parseInt(item.market_price) - item.payments.map(m => parseInt(m.price)).reduce((a, b) => a +
            b);
          this.payment_type = item.payments[item.payments.length - 1].payment_type
          this.disabledItem = false;
        } else if (item.cur_status_id === 1) {
          this.form.price = parseInt(item.market_price)
          this.form.payment_type_id = null
          this.disabledItem = false;
        }

        this.currency = item.currency
      },
      handleClose() {
        this.$emit('handleClose')
      },
      handleSubmit() {
        const isValid = this.$refs.observer.validate();
        if (isValid) {
          this.disabledButton = true;
          setTimeout(() => {
            this.disabledButton = false;
          }, 1000)
          this.form.contract_id = this.contract.id;
          this.form.payment_type_id = this.payment_type.id
          this.form.currency_id = this.currency.id

          contractPayment(this.form).then(() => {
            this.handleClose();
            this.disabledButton = false;
          })
        }

      },
      addQRData() {
        this.codeModal = true;
        this.$refs.qrInput.focus()
      },
      getQrContract() {
        getQRCode({
          qr_code: this.get_contract_by_qr
        }).then(res => {
          this.codeModal = false;
          let data = res.data.data;
          this.contract = data;
          this.currency = data.currency;
          console.log(data);


          // this.form.price = data.market_price

          if (data.cur_status_id === 3) {
            this.disabledItem = true;
          } else if (data.cur_status_id === 2) {
            this.form.price = parseInt(data.market_price) - data.payments.map(m => parseInt(m.price)).reduce((a, b) => a +
              b);
            this.payment_type = data.payments[data.payments.length - 1].payment_type
            this.disabledItem = false;
          } else if (data.cur_status_id === 1) {
            this.form.price = parseInt(data.market_price)
            this.form.payment_type_id = null
            this.disabledItem = false;
          }
          // this.payment_type = null

          // item.client.contacts =  JSON.parse(item.client.contacts);
          // this.clientName = item.client.full_name;
          // this.clientNumbers = item.client.contacts;
          // this.author = item.author.name
        })
      }
    },
    created() {
      this.fetchMethods();
      this.fetchCurrencies();
    }
  }
</script>