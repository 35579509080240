<template>
  <div class="w-100">
    <div id="cheque" style="width: 408px;">
      <div class="w-100">
        <img :src="'/logo/logo.jpeg'" style="width: 408px" alt="">
      </div>
      <div style="border-top: 1px dashed black;"></div>
      <div style="border-top: 1px dashed black;"></div>
      <div class="w-100 mt-2">
        <p>
          Ф.И.О: <strong>{{chequeData.client.full_name}}</strong>
        </p>
        <p>
          Направления: <strong>{{getDirection}}</strong>
        </p>
        <p>
          На оплату: <strong>{{chequeData.price}} {{chequeData.currency.name}}</strong>
        </p>
        <p>
          <em>Оплата строго принимается по курсу ЦБ Узбекистана!</em>
        </p>
        <p>
          Оплаченная сумма: <strong>{{chequeData.price}} {{chequeData.currency.name}}</strong>
        </p>
      </div>
      <div class="mt-4">
        <div class="w-100 text-center">
          <p>+998 (71) 200-00-00 +998 (71) 242-00-00</p>
        </div>
        <div class="w-100 text-center">
          <p>Спасибо за выбор!</p>
        </div>
        <div class="w-100 text-center">
          <p>Будем рады увидеть Вас снова в нашем офисе!</p>
        </div>
      </div>
    </div>

    <div class="w-100 text-center">
      <b-button variant="primary" @click="printCheque" :disabled="disabled">Print</b-button>
    </div>
  </div>
</template>

<script>
  import {
    getSericeItemByService
  } from '@/api/payments'

  export default {
    name: 'ChequeModal',
    props: {
      chequeData: {
        required: true,
      }
    },
    data() {
      return {
        service_item: null,
        disabled: false,
      }
    },
    computed: {
      getDirection() {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
        if (this.service_item) {
          var text = '';
          if (typeof this.service_item.countries !== 'undefined') {
            this.service_item.countries.forEach((item, index) => {
              text += item.name_ru;
              if (index !== this.service_item.countries.length - 1)
                text += ' -> ';
            })
          } else if (typeof this.service_item.country != 'undefined') {
            text += this.service_item.country.name_ru;
          } else if (typeof this.service_item.from_country !== 'undefined') {
            text += this.service_item.from_country.name_ru + ' -> ';
            text += this.service_item.to_country.name_ru;
          }

          return text;
        }
      }
    },
    methods: {
      findSericeItem() {
        getSericeItemByService({
          service_id: this.chequeData.service_id,
          service_item_id: this.chequeData.service_item_id
        }).then(res => {
          this.service_item = res.data.data;
        })
      },
      printCheque() {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById('cheque').innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        // mywindow.close();
      }
    },
    watch: {
      chequeData: {
        immediate: true,
        handler(val) {
          this.findSericeItem()
        }
      }
    }
  }
</script>

<style scoped>

</style>
